import axios from 'axios';
import { getCoreServiceUrl } from '../components/Util.js';
import TestConfigService from './TestConfigService.js';

class TestExecService {
  coreServiceUrl = `${getCoreServiceUrl()}/core-srv`;
  baseUrl = `${this.coreServiceUrl}/test-exec`;

  getCompletedTestIdsByApplication() {
    let url = `${this.baseUrl}/completed-list`;

    return axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        validateStatus: function (status) {
          return status === 200;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  getById(testId) {
    let url = `${this.baseUrl}/${testId}`;
    return axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        validateStatus: function (status) {
          return status === 200;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  async getByFilters(params) {
    let url = `${this.coreServiceUrl}/test-exec/filter`;
    return await axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: params,
        validateStatus: function (status) {
          return status === 200;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  createOrUpdate(data, isCreate) {
    if (isCreate) {
      return this.create(data);
    } else {
      return this.update(data);
    }
  }

  create(data) {
    return axios
      .post(this.baseUrl, data, {
        validateStatus: function (status) {
          return status === 201;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  update(data) {
    let url = `${this.baseUrl}/${data.testId}`;
    return axios
      .put(url, data, {
        validateStatus: function (status) {
          return status === 200;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  startOrStopTest(testId, action) {
    let url = `${this.baseUrl}/${action}/${testId}`;
    return axios
      .post(url, null, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        validateStatus: function (status) {
          return status === 200;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  getRecentFailure(testId) {
    let url = `${this.coreServiceUrl}/error-msg/${testId}/recent`;
    return axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        validateStatus: function (status) {
          return status === 200 || status === 404;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  getTestResultData(url) {
    return axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        validateStatus: function (status) {
          return status === 200 || status === 404;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  testConfigService = new TestConfigService();

  getConfigFromGitHub(testTemplate) {
    return this.testConfigService.getConfigFromGitHub(testTemplate);
  }
}

export default TestExecService;
