import axios from 'axios';

function handleError(error) {
  console.error('Error Message= ', error);
  return error;
}
class VaultService {
  async postVault(url, data) {
    return await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('ERROR', error);
        handleError(error);
        return error;
      });
  }

  async getVaultByOnboardingConfigId(url) {
    return await axios
      .get(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('ERROR', error);
        handleError(error);
        return error;
      });
  }

  async updateVault(url, data) {
    return await axios
      .put(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('ERROR', error);
        handleError(error);
        return error;
      });
  }
}

export default VaultService;
