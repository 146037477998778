import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import { McDateRange } from '@maersk-global/mds-react-wrapper/components-core/mc-date-range';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowErrorNotification } from '../common/notification.jsx';
import Loading from '../components/pages/Loading.jsx';
import { formatDateTime, getCoreServiceUrl } from '../components/Util.js';
import IcTable from '../components/util/IcTable.jsx';
import TestTmplService from '../service/TestTmplService.js';
import { McInput } from '@maersk-global/mds-react-wrapper';
import TestExecService from '../service/TestExecService.js';
import UserProfileService from '../service/UserProfileService.js';
import { McTypeahead } from '@maersk-global/mds-react-wrapper/components-core/mc-typeahead';
export default function ListTestExcnts() {
  const testExecService = new TestExecService();
  const userProfileService = new UserProfileService();
  let testTmplService = new TestTmplService();
  const navigate = useNavigate();
  const defaultTestExecutionListData = {
    data: [],
    totalCount: -1,
    totalPages: 0,
    currentPage: 0,
    pageSize: 50
  };
  const [filteredData, setFilteredData] = useState(defaultTestExecutionListData);
  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);
  const [testIdFilter, setTestIdFilter] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedTemplatename, setSelectedTemplatename] = useState('');
  const [createdByFilter, setCreatedByFilter] = useState([]);
  const [createdByList, setCreatedByList] = useState([]);

  const [templateNameOptions, setTemplateNameOptions] = useState([]);
  const defaultDatefilter = {
    from: null,
    to: null,
    fromToChanged: false
  };

  const [datefilterData, setDateFilterData] = useState(defaultDatefilter);
  const setDateFilter = (event) => {
    if (
      datefilterData.from !== event.target.value.from ||
      datefilterData.to !== event.target.value.to
    ) {
      setDateFilterData((prevState) => ({
        ...prevState,
        from: event.target.value.from,
        to: event.target.value.to,
        fromToChanged: true
      }));
    }
  };
  const navigateToExecuteTest = (e, testId) => {
    navigate(`/perf-testing/executions/${testId}`);
  };
  const [isClear, setIsClear] = useState(false);
  const handleClearFilter = () => {
    setTestIdFilter('');
    setSelectedTemplateId('');
    setSelectedTemplatename('');
    setCreatedByFilter([]);
    setDateFilterData({ from: null, to: null, fromToChanged: false });
    setIsClear(true);
  };
  const handleTemplateNameInput = async (event) => {
    setSelectedTemplatename(event.target.value);
    const inputValue = event.target.value;
    if (inputValue.length >= 3) {
      getTemplateOptions(inputValue);
    }
  };
  const handleTemplateNameSelect = async (event) => {
    const selectedOption = templateNameOptions.find(
      (option) => `${option.templateName} ` === event.target.value
    );
    if (selectedOption) {
      setSelectedTemplateId(selectedOption.templateId);
      setSelectedTemplatename(`${selectedOption.templateName}-${selectedOption.templateId}`);
    }
  };

  const getTemplateOptions = (selectedtemplateName) => {
    let params = {
      pageNum: 0,
      pageSize: 100,
      templateName: selectedtemplateName
    };
    testTmplService
      .getAllTestTemplate(getCoreServiceUrl() + `/core-srv/test-tmpl/list`, params)
      .then((response) => {
        const options = response.data.data.map((item) => ({
          templateId: item.templateId.toString(),
          templateName: item.templateName
        }));
        setTemplateNameOptions(
          response.data.data.map((item) => ({
            templateId: item.templateId.toString(),
            templateName: item.templateName
          }))
        );
        setApiStatus({ status: Status.Success, message: '' });
        return options;
      })
      .catch((error) => {
        if (error instanceof Error) {
          console.error('Error while retrieving templates', error);
          setApiStatus({
            status: Status.Error,
            message: 'Something unexpected happened. Please try again by refreshing the page.'
          });
        }
      });
  };
  useEffect(() => {
    userProfileService
      .getUserForOnboardingConfigId()
      .then((response) => {
        const responseData = response.data;
        const userNameList = responseData
          .map((user) => user.userName || user.clientId)
          .sort((a, b) => a.localeCompare(b));
        setCreatedByList(userNameList);
      })
      .catch((error) => {
        console.error('Error while retrieving User list: ', error);
        setCreatedByList([]);
      });
  }, []);

  useEffect(() => {
    handleApplyFilter();
  }, [filteredData.currentPage, isClear]);

  const handleApplyFilter = () => {
    setIsClear(false);
    setApiStatus({ status: Status.InProgress, message: '' });
    const params = {
      pageNum: filteredData.currentPage,
      pageSize: filteredData.pageSize
    };
    if (testIdFilter) params.testId = testIdFilter;
    if (selectedTemplateId) params.templateId = selectedTemplateId;
    if (createdByFilter.length > 0) params.createdBy = createdByFilter;
    if (datefilterData.from) params.from = datefilterData.from;
    if (datefilterData.to) params.to = datefilterData.to;
    testExecService
      .getByFilters(params)
      .then((response) => {
        setFilteredData({
          ...filteredData,
          data: response.data.data,
          totalCount: response.data.totalCount,
          totalPages: response.data.totalPages
        });
        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        console.error('Error while retrieving TestExecution by filters: ', error);
        setFilteredData({
          ...filteredData,
          data: [],
          totalCount: 0,
          totalPages: 0
        });
      });
  };

  const getButtonLabel = (status) => {
    let label = 'View Test';
    if (status === 'DRAFT') {
      label = 'Edit Test';
    } else if (status === 'FAILED_TO_START' || status === 'FAILED_TO_COMPLETE') {
      label = 'View Failure';
    } else if (status === 'COMPLETED') {
      label = 'View Report';
    }
    return label;
  };

  return (
    <div style={{ width: 'fit-content' }}>
      <h5 className="app__page-title">Performance Engineering &gt; Test Executions</h5>
      {apiStatus.status === Status.InProgress ? <Loading /> : ''}
      {apiStatus.status === Status.Error ? (
        <ShowErrorNotification message={apiStatus.message} />
      ) : null}
      <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '16px' }}>
        <div style={{ flex: 0.11, marginRight: '10px' }}>
          <McInput
            type="text"
            label="Test ID"
            placeholder="Test ID"
            value={testIdFilter}
            onInput={(e) => setTestIdFilter(e.target.value)}
          />
        </div>
        <div style={{ flex: 0.35 }}>
          <McTypeahead
            name="templateNameFilter"
            label="Template Name"
            placeholder="Start typing template name"
            value={selectedTemplatename}
            onInput={handleTemplateNameInput}
            optionsheight="265px"
            data={(templateNameOptions || []).map((option) => ({
              label: `${option.templateName} `,
              sublabel: `Template ID: ${option.templateId}`,
              value: `${option.templateName}-${option.templateId}` // Use a combination of templateName and templateId as the value
            }))}
            disablefilter={true}
            minchars="3"
            optionselected={handleTemplateNameSelect}
          />
        </div>
        <div style={{ marginRight: '10px', marginLeft: '10px' }}>
          <div>
            <McDateRange
              name="createDateTimeFilter"
              format="YYYY-MM-DD"
              fit="medium"
              style={{ width: 5 }}
              input={(event) => setDateFilter(event)}
              value={{ from: datefilterData.from, to: datefilterData.to }}
            />
          </div>
        </div>
        <div style={{ flex: 0.45 }}>
          <McSelect
            name="createdByFilter"
            label="Created By"
            value={createdByFilter}
            placeholder="Created By"
            optionsheight="265px"
            optionselected={(event) => setCreatedByFilter(event.target.value)}>
            {createdByList.map((d) => (
              <McOption key={d} value={d}>
                {d}
              </McOption>
            ))}
          </McSelect>
        </div>
        <div style={{ flex: 0.04 }}>
          <McButton variant="outlined" style={{ marginLeft: '8px' }} click={handleApplyFilter}>
            Apply
          </McButton>
        </div>
        <div style={{ flex: 0.08 }}>
          <McButton variant="outlined" style={{ marginLeft: '8px' }} click={handleClearFilter}>
            {/* <McIcon icon="minus-circle-solid" /> */}
            Clear
          </McButton>
        </div>
      </div>
      <IcTable
        name="Test Execution"
        data={filteredData}
        columns={columns}
        datakey="testId"
        footer
        className="app__page-table-pagination"
        disableplaceholderfooters
        onPageChange={(nextPage) => {
          setFilteredData({
            ...filteredData,
            currentPage: nextPage
          });
        }}>
        {filteredData.data?.map((row) => (
          <React.Fragment key={row.testId}>
            <div slot={`${row.testId}_templateName`}>{row.testTemplate.templateName}</div>
            <div slot={`${row.testId}_createDateTime`}>{formatDateTime(row.createDateTime)}</div>
            <div slot={`${row.testId}_action`}>
              <McButton variant="outlined" onClick={(e) => navigateToExecuteTest(e, row.testId)}>
                {getButtonLabel(row.status)}
              </McButton>
            </div>
          </React.Fragment>
        ))}
      </IcTable>
    </div>
  );
}

const columns = [
  {
    id: 'testId',
    sortDisabled: true,
    label: 'Test ID'
  },
  {
    id: 'testName',
    sortDisabled: true,
    label: 'Test Name'
  },
  {
    id: 'templateName',
    sortDisabled: true,
    label: 'Template Name'
  },
  {
    id: 'createdBy',
    sortDisabled: true,
    label: 'Created By'
  },
  {
    id: 'status',
    sortDisabled: true,
    label: 'Status'
  },
  {
    id: 'createDateTime',
    sortDisabled: true,
    label: 'Created Date Time'
  },
  {
    id: 'action',
    sortDisabled: true,
    label: 'Action'
  }
];

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
