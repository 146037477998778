import {
  McIcon,
  McTooltip,
  McButton,
  McButtonGroup,
  McButtonGroupItem
} from '@maersk-global/mds-react-wrapper';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowErrorNotification } from '../common/notification.jsx';
import Loading from '../components/pages/Loading.jsx';
import { formatDateTime, getCoreServiceUrl, getGitHubLink } from '../components/Util.js';
import ComponentLink from '../components/util/ComponentLink.jsx';
import IcTable from '../components/util/IcTable.jsx';
import TestTmplService from '../service/TestTmplService.js';
import TestExecService from '../service/TestExecService';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
function ListTestTmpls() {
  const navigate = useNavigate();
  const { graphData } = UserInfo();
  let testTmplService = new TestTmplService();
  const testExecService = new TestExecService();
  const navigateToExecuteTest = (e, templateData) => {
    navigate('/perf-testing/executions/new', {
      state: {
        templateData: templateData
      }
    });
  };
  const [testTemplateData, setTestTemplateData] = useState({
    testName: '',
    testTemplate: {},
    testType: 'LOAD TEST',
    optionsJson: {},
    varsJson: {},
    selLoadGeneratorList: [],
    createdBy: '',
    createdById: ''
  });

  const fetchTestConfig = async (testTemplate) => {
    try {
      const config = await testExecService.getConfigFromGitHub(testTemplate);
      if (config.optionsJsonNotFound) {
        setApiStatus({
          status: Status.Warning,
          message: 'Standard directory layout not followed'
        });
      } else {
        setApiStatus({ status: Status.Success, message: '' });
      }
      return config;
    } catch (error) {
      console.error('Error while getting test config from GitHub', error);
      setApiStatus({
        status: Status.Error,
        message: 'Something unexpected happened. Please try again.'
      });
      return null;
    }
  };

  const templateData = async (testTemplate) => {
    try {
      const config = await fetchTestConfig(testTemplate);
      const updatedTemplateData = {
        testName: testTemplate.templateName,
        testTemplate: testTemplate,
        testType: 'LOAD TEST',
        optionsJson: config.optionsJson,
        varsJson: config.varsJson,
        selLoadGeneratorList: [
          {
            region: 'europe',
            zoneList: ['west']
          }
        ],
        createdBy: graphData?.name,
        createdById: graphData?.username
      };
      setTestTemplateData(updatedTemplateData);
      return updatedTemplateData;
    } catch (error) {
      console.error('Error while getting test template data', error);
      setApiStatus({
        status: Status.Error,
        message: 'Something unexpected happened. Please try again.'
      });
      return null;
    }
  };

  const navigateToScheduleTest = async (e, testTemplate) => {
    const updatedTemplateData = await templateData(testTemplate);
    if (updatedTemplateData) {
      navigate('/perf-testing/schedules/new', {
        state: {
          templateData: updatedTemplateData
        }
      });
    }
  };

  const navigateToIntegrateTest = async (e, testTemplate) => {
    const updatedTemplateData = await templateData(testTemplate);
    if (updatedTemplateData) {
      navigate('/perf-testing/integrations/new', {
        state: {
          templateData: updatedTemplateData
        }
      });
    }
  };

  const handleAddTest = (e) => {
    e.preventDefault();
    navigate('/perf-testing/templates/new');
  };

  const defaultTemplateListData = {
    data: [],
    totalCount: -1,
    totalPages: 0,
    currentPage: 0,
    pageSize: 50
  };
  const [TemplateListData, setTemplateListData] = useState(defaultTemplateListData);

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);

  const getTemplateData = () => {
    setApiStatus({ status: Status.InProgress, message: '' });
    let params = {
      pageNum: TemplateListData.currentPage,
      pageSize: TemplateListData.pageSize
    };
    testTmplService
      .getAllTestTemplate(getCoreServiceUrl() + `/core-srv/test-tmpl/list`, params)
      .then((response) => {
        const responseData = response.data;
        setTemplateListData({
          ...TemplateListData,
          data: responseData.data,
          totalCount: responseData.totalCount,
          totalPages: responseData.totalPages
        });
        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        if (error instanceof Error) {
          console.error('Error while retrieving templates', error);
          setApiStatus({
            status: Status.Error,
            message: 'Something unexpected happened. Please try again by refreshing the page.'
          });
        }
      });
  };
  React.useEffect(() => {
    getTemplateData();
  }, [TemplateListData.currentPage]);

  return (
    <div style={{ width: 'fit-content' }}>
      <h5 className="app__page-title">Performance Engineering &gt; Test Templates</h5>
      {/* <LoadingMask isOpen={apiStatus.status === Status.InProgress} /> */}
      {apiStatus.status === Status.InProgress ? <Loading /> : ''}
      {apiStatus.status === Status.Error ? (
        <ShowErrorNotification message={apiStatus.message} />
      ) : null}
      <div style={{ marginBottom: '16px' }}>
        <McButton variant="outlined" onClick={handleAddTest}>
          New Test Template
        </McButton>
      </div>
      <IcTable
        name="Test Template"
        data={TemplateListData}
        columns={columns}
        datakey="templateId"
        footer
        disableplaceholderfooters
        onPageChange={(nextPage) => {
          setTemplateListData({ ...TemplateListData, currentPage: nextPage });
        }}>
        {TemplateListData.data?.map((row) => (
          <React.Fragment key={row.templateId}>
            <div slot={`${row.templateId}_templateId`}>
              <ComponentLink route={`/perf-testing/templates/${row.templateId}`}>
                {row.templateId}
              </ComponentLink>
            </div>
            <div slot={`${row.templateId}_testScript`}>
              <a href={getGitHubLink(row)} target="_blank" rel="noopener noreferrer">
                {row.testScript}
              </a>
            </div>
            <div slot={`${row.templateId}_status`}>
              <McIcon
                icon={row.status === 'ACTIVE' ? 'check-circle' : 'times-circle'}
                color={row.status === 'ACTIVE' ? 'green' : 'red'}></McIcon>
            </div>
            <div slot={`${row.templateId}_createDateTime`}>
              {formatDateTime(row.createDateTime)}
            </div>
            <div slot={`${row.templateId}_actions`} style={{ display: 'flex' }}>
              <McButtonGroup selectiontype="single">
                <McTooltip fit="small">
                  <McButtonGroupItem
                    slot="trigger"
                    variant="plain"
                    hiddenlabel
                    value="execute"
                    label="Execute Test"
                    icon="play"
                    disabled={row.status !== 'ACTIVE'}
                    onClick={(e) => navigateToExecuteTest(e, row)}></McButtonGroupItem>
                  <span>Execute Test</span>
                </McTooltip>
                <McTooltip fit="small">
                  <McButtonGroupItem
                    slot="trigger"
                    variant="plain"
                    hiddenlabel
                    value="schedule"
                    label="Schedule Test"
                    icon="clock-alarm"
                    disabled={row.status !== 'ACTIVE'}
                    onClick={(e) => navigateToScheduleTest(e, row)}></McButtonGroupItem>
                  <span>Schedule Test</span>
                </McTooltip>
                <McTooltip fit="small">
                  <McButtonGroupItem
                    slot="trigger"
                    variant="plain"
                    hiddenlabel
                    value="integrate"
                    label="Integrate with GitHub Actions"
                    icon="wrench"
                    disabled={row.status !== 'ACTIVE'}
                    onClick={(e) => navigateToIntegrateTest(e, row)}></McButtonGroupItem>
                  <span>Integrate with GitHub Actions</span>
                </McTooltip>
              </McButtonGroup>
            </div>
          </React.Fragment>
        ))}
      </IcTable>
    </div>
  );
}
export default ListTestTmpls;
const columns = [
  {
    id: 'templateId',
    label: 'Template ID',
    sortDisabled: true
  },
  {
    id: 'templateName',
    label: 'Template Name',
    sortDisabled: true
  },
  {
    id: 'testScript',
    label: 'Test Script',
    sortDisabled: true
  },
  {
    id: 'status',
    label: 'Status',
    sortDisabled: true
  },
  {
    id: 'createDateTime',
    label: 'Created  Date Time',
    sortDisabled: true
  },
  {
    id: 'actions',
    label: 'Actions',
    sortDisabled: true
  }
];
const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
