import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication
} from '@azure/msal-react';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import HeaderNew from './HeaderNew';
import Insights from './Insights/Insights';
import OnboardedApplications from './Insights/OnboardedApplicationsinsights';
import Navigation from './Navigation';
import Notifications from './Notifications/Notifications';
import ApplicationDetails from './Onboarding/ApplicationDetails';
import ApplicationOnboarding from './Onboarding/ApplicationOnboarding';
import Applications from './Onboarding/Applications';
import OnboardUser from './Onboarding/OnboardUser';
import UserOnboarding from './Onboarding/UserOnboarding';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import Secrets from './Secrets/Secrets';
import ConfigureTest from './addtest/ConfigureTest';
import MdsAddTest from './addtest/mdsAddTest';
import ConfigureChaosTest from './chaos/ConfigureChaosTest';
import ListChaosTestExcnts from './chaos/ListChaosTestExcnts';
import AppHeaderRequestInterceptor from './components/auth/AppHeaderRequestInterceptor';
import UserAppAuthorizedTemplate from './components/auth/UserAppAuthorizedTemplate';
import ListScheduleTests from './components/scheduling/ListScheduleTests';
import ScheduleTest from './components/scheduling/ScheduleTest';
import Health from './health/health';
import { UserAppAuthorizationProvider } from './hooks/UserAppAuthorizationProvider';
import ListTestExcnts from './search/ListTestExcnts';
import ListTestTmpls from './search/ListTestTmpls';
import HashicorpVault from './vault/HashicorpVault';
import AzureVault from './vault/AzureVault.js';
import IntegrationTest from './components/integration/IntegrationTest.jsx';
import ListTestIntegrations from './components/integration/ListTestIntegrations';
const MainContent = () => {
  const { accounts } = useMsal();
  const loc = useLocation();
  return (
    <div className="app__layout">
      <AuthenticatedTemplate>
        <UserAppAuthorizationProvider graphData={accounts[0]}>
          <HeaderNew />
          <div className="app__navigation-page-layout">
            <Navigation />
            <div className="app__page-layout">
              <UserAppAuthorizedTemplate>
                <AppHeaderRequestInterceptor>
                  <Routes>
                    <Route path="/" element={<Navigate to="/applications" />}></Route>
                    <Route
                      path="/perf-testing/templates/new"
                      element={
                        <PrivateRoute>
                          <MdsAddTest />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/templates/:templateId"
                      element={
                        <PrivateRoute>
                          <MdsAddTest />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/templates"
                      element={
                        <PrivateRoute>
                          <ListTestTmpls />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/executions"
                      element={
                        <PrivateRoute>
                          <ListTestExcnts />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      exact
                      path="/perf-testing/executions/:testId"
                      element={
                        <PrivateRoute>
                          <ConfigureTest />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/executions/new"
                      element={
                        <PrivateRoute>
                          <ConfigureTest />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/schedules/new"
                      element={
                        <PrivateRoute>
                          <ScheduleTest />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/schedules"
                      element={
                        <PrivateRoute>
                          <ListScheduleTests />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/schedules/:id"
                      element={
                        <PrivateRoute>
                          <ScheduleTest />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/config/notifications"
                      element={
                        <PrivateRoute>
                          <Notifications />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/config/azure-key-vault"
                      element={
                        <PrivateRoute>
                          <AzureVault />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/chaos-testing/executions/new"
                      element={
                        <PrivateRoute>
                          <ConfigureChaosTest />
                        </PrivateRoute>
                      }></Route>

                    <Route
                      exact
                      path="/chaos-testing/executions/:testId"
                      element={
                        <PrivateRoute>
                          <ConfigureChaosTest />
                        </PrivateRoute>
                      }></Route>

                    <Route
                      exact
                      path="/chaos-testing/executions"
                      element={
                        <PrivateRoute>
                          <ListChaosTestExcnts />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/health"
                      element={
                        <PrivateRoute>
                          <Health />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/insights/perf-tests"
                      element={
                        <PrivateRoute>
                          <Insights />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/insights/applications"
                      element={
                        <PrivateRoute>
                          <OnboardedApplications />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/config/hashicorp-vault"
                      element={
                        <PrivateRoute>
                          <HashicorpVault />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      exact
                      path="/config/secrets"
                      element={
                        <PrivateRoute>
                          <Secrets />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/users/new"
                      element={
                        <PrivateRoute>
                          <UserOnboarding />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/users/onboard"
                      element={
                        <PrivateRoute>
                          <OnboardUser />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/applications/new"
                      element={
                        <PrivateRoute>
                          <ApplicationOnboarding />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/applications"
                      element={
                        <PrivateRoute>
                          <Applications />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/applications/:applicationId"
                      element={
                        <PrivateRoute>
                          <ApplicationDetails />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/integrations/new"
                      element={
                        <PrivateRoute>
                          <IntegrationTest />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/integrations"
                      element={
                        <PrivateRoute>
                          <ListTestIntegrations />
                        </PrivateRoute>
                      }></Route>
                    <Route
                      path="/perf-testing/integrations/:id"
                      element={
                        <PrivateRoute>
                          <IntegrationTest />
                        </PrivateRoute>
                      }></Route>
                  </Routes>
                </AppHeaderRequestInterceptor>
              </UserAppAuthorizedTemplate>
            </div>
          </div>
        </UserAppAuthorizationProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5 style={{ textAlign: 'center' }} className="card-title">
          Please wait...
        </h5>
      </UnauthenticatedTemplate>
    </div>
  );
};

function App() {
  const { error } = useMsalAuthentication(InteractionType.Redirect);
  if (error) {
    console.error('Error during authentication: ', error);
    return <div>Error: {error.errorCode + ':' + error.errorMessage}</div>;
  }

  return <MainContent />;
}

export default App;
